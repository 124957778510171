import React from 'react'
import {che} from 'react-icons'

import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';


const PasswordResetResponse = () => {
  return (
    <div className="d-flex align-items-center justify-content-center mt-5  ">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="text-center mb-4">
                <img src="/NWHlogo.PNG" alt="Medihub" style={{width: "180px"}} />
              </div>
              <h5 className="text-success font-weight-bold text-center mb-4">Success <IoShieldCheckmarkSharp /></h5>
              <p className=' text-md text-center'>Your password reset was succesiful, please proceed and login with your new password</p>

            
            <Link className=' btn btn-primary w-100' to={`/`}>Login</Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PasswordResetResponse
