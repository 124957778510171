import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../apiConfig";
import { Link, useParams } from "react-router-dom";
import LogoutButton from "../../common/LogoutService";


const EablStaffView = () => {
    const [eablStaffMember, setEablStaffMember] = useState({});
    const [isLoading, setLoading] = useState(false)
   

    const params = useParams()


    const getEablStaffMember = async () => {
       
        try {
          const response = await fetch(`${API_BASE_URL}/accounts/eabl_staff_member/${params.id}/`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
    
          //   if(response.status === 401) {
          //     handleTokenExpiry()
          //     return
          //   }
    
          const data = await response.json();
          setEablStaffMember(data);
          console.log("Eabl Staff Members", data);
        } catch (error) {
          console.log("an error occurred while fetching eabl staff member");
        } finally {
          setLoading(false);
        }
      };



      useEffect(() => {
        getEablStaffMember()
      }, [params.id])

  return (
    <div className="" style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"></div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/main/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Eabl Staff Member</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
                <div className="card-body">
                  

                  <div className="container mt-2">
                    <div className="row justify-content-center">
                      
                      <div className="d-flex align-items-center justify-content-between">
                        <img src="/EABL.png" alt="EABL logo" width={100} />

                        <img src="/NWHlogo.PNG" alt="Medihub logo" width={100} />
                      </div>
                      <div className=" col-12">
                       
                        <div className="card rounded p-3 shadow-lg">
                          <div>
                            <div className="row">
                                

                                 {/* OOP Number */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">Medical Card No</label>
                                <p>{eablStaffMember.medical_card_no}</p>
                              </div>

                              {/* First Name */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">First Name</label>
                                <p>{eablStaffMember.first_name}</p>
                              </div>


                                  {/* Middle Name */}
                                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">Middle Name</label>
                                <p>{eablStaffMember.middle_name}</p>
                              </div>

                               {/* Last Name */}
                               <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">Last Name</label>
                                <p>{eablStaffMember.last_name}</p>
                              </div>

                                {/* cost_center */}
                               <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">Cost Center</label>
                                <p>{eablStaffMember.cost_center}</p>
                              </div>

                                {/* eabl_coy */}
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">EABL COY</label>
                                <p>{eablStaffMember.eabl_coy}</p>
                              </div>

                               {/* mem_number */}
                               <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">Member Number</label>
                                <p>{eablStaffMember.mem_number}</p>
                              </div>
                            
                            

                              {/* Gender */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="gender">Gender</label>
                                <p>{eablStaffMember.gender}</p>
                              </div>

                              {/* DOB */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="location">Location</label>
                                <p>{eablStaffMember.date_of_birth}</p>
                              </div>

                             
                            </div>

                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EablStaffView;
