import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import TableLoader from "../../common/TableLoader";
import Spinner from "../../common/Spinner";
import { API_BASE_URL } from "../../apiConfig";
import DeleteConfirm from "../../common/DeleteConfirm";
import { Link } from "react-router-dom";
// import useTokenExpiration from "../../../useTokenExpiration";

const Messages = () => {
  const [messages, setMessgaes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  //   const { errorMessage, handleTokenExpiry } = useTokenExpiration();

  const [show, setShow] = useState(false);

  //   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/sms/delete_message/${selectedMessageId}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      //   if(response.status === 401) {
      //     handleTokenExpiry()
      //     return
      //   }

      if (response.ok) {
        getMessages();
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("An error occured while deleting reception patient", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const handleDelete = (messageId) => {
    setSelectedMessageId(messageId);
  };

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

 

  const getMessages = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/sms/get_messages/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      //   if(response.status === 401) {
      //     handleTokenExpiry()
      //     return
      //   }

      const data = await response.json();
      setMessgaes(Array.isArray(data) ? data : []);
      console.log("Messages", data);
    } catch (error) {
      console.log("an error occurred while fetching patients");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredMessages = messages.filter((message) => {
    return (
      message.sms?.toLowerCase().includes(searchText.toLowerCase()) ||
      message.status
        ?.toLowerCase()
        .includes(searchText.toLocaleLowerCase()) 
      

       
    );
  });

  const columns = [
    {
      name: "Messages ",
      selector: (row) => `${row.sms.slice(0, 25)} ... `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Date sent",
      selector: (row) => `${row.date_sent} `,
      sortable: true,
      className: "bold-header",
    },

   

    {
      name: "status",
      selector: (row) =>
        row.status === "Success" ? (
          <span className=" badge badge-success ">success</span>
        ) : (
          <span className="badge badge-secondary text-white rounded">failed</span>
        ),
      sortable: true,
      className: "bold-header",
    },

    {
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn  btn-sm text-xs"
            type="button"
            id={`dropdownMenuButton-${row.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="fa fa-ellipsis-v"></span>
          </button>
          <div
            className="dropdown-menu text-xs"
            aria-labelledby={`dropdownMenuButton-${row.id}`}
          >
            <>
              

              <a className="text-white" href={`/main/sms/message/${row.id}/`}>
                <button className="dropdown-item ">View</button>
              </a>

              <button
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDelete(row.id);
                  handleShow();
                }}
              >
                Delete
              </button>
            </>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "60px", // Set a minimum width for the dropdown button
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      getMessages();
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="content-wrapper" style={{ minHeight: "90vh" }}>
          <section className="content-header ">
            <div className="container-fluid ">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h5>SMS Records</h5>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/main/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      SMS Records
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-body ">
                      <div className="container   mt-5">
                        <div className="row ">
                          <div className="col-sm-6">
                            <h3 className="card-title text-bold">
                              SMS Records
                            </h3>
                          </div>
                          <div className="col-sm-6 text-right ">

                          <Link
                              to={`/main/sms/select_mode`}
                              type="button"
                              className="button-primary"
                              style={{
                                float: "right",
                                marginRight: "10px",
                                fontSize: "0.75rem",
                                padding: "0.25rem 0.5rem",
                              }}
                            >
                              Send sms
                            </Link>  
                            <button
                              type="button"
                              className="button-primary-outline"
                              style={{ float: "right", marginRight: "10px" }}
                              onClick={() => {
                                const csv = messages.map((message) => ({
                                    message_id: message.message_id,
                                    sms: message.sms,
                                    date_created: message.date_created,
                                    date_sent: message.date_sent,
                                    status: message.status,
                                    recipient_number: message.recipient_number,
                                    sms_cost: message.sms_cost,
                                
                                }));
                                const csvData = convertToCSV(csv);
                                const blob = new Blob([csvData], {
                                  type: "text/csv",
                                });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.setAttribute("href", url);
                                a.setAttribute(
                                  "download",
                                  "Messages.csv"
                                );
                                a.click();
                              }}
                            >
                              Export to CSV
                            </button>

                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Search..."
                              value={searchText}
                              onChange={handleSearch}
                              style={{
                                width: "200px",
                                float: "right",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )} */}

                      <div className="container border rounded mt-5">
                        <div>
                          {loading}
                          <DataTable
                            columns={columns}
                            data={filteredMessages}
                            fixedHeader
                            responsive={true}
                            selectableRows
                            progressPending={loading}
                            progressComponent={<TableLoader />}
                            onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                            striped // Enable row striping
                            highlightOnHover // Highlight row
                            pagination // Paginate
                          />
                        </div>

                        {show && (
                          <DeleteConfirm
                            handleConfirmDelete={handleConfirmDelete}
                            itemName={
                              messages.find(
                                (message) => message.id === selectedMessageId
                              )?.sms
                            }
                            handleClose={handleClose}
                            handleShow={handleShow}
                            show={show}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Messages;
