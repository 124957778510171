import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../apiConfig";
import { Link, useNavigate } from "react-router-dom";

const SendBulkSMS = () => {
  const [formData, setFormData] = useState({
    phone_numbers: "",
    message: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate();



  const getPhoneNumbers = async () => {
       
    try {
      const response = await fetch(`${API_BASE_URL}/reception/reception_patients/phone_numbers/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

    

      const data = await response.json();
      setPhoneNumbers(data)
    
         
         const phoneNumbersString = data.phone_numbers.join(", "); 
         console.log("formatted numbers", phoneNumbersString)

    
         setFormData((prevData) => ({
           ...prevData,
           phone_numbers: phoneNumbersString,
         }));
      
     
    } catch (error) {
      console.log("an error occurred while fetching numbers");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getPhoneNumbers()
  }, [])


  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    
    const phoneNumbersArray = formData.phone_numbers.split(",").map(number => number.trim());

    try {
      const response = await fetch(`${API_BASE_URL}/sms/sms_send_bulk/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          phone_numbers: phoneNumbersArray,
          message: formData.message
        }),
      });

      if (response.ok) {
        console.log("SMS sent successfully");
        navigate("/main/sms/messages");
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      console.log("An error occurred while submitting data");
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5 className="text-primary">Send SMS</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/main/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Send SMS</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4">
                    <h3 className="card-title text-primary">Send SMS</h3>
                    <Link to="/main/sms/messages" className="button-primary" style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}>
                      Back
                    </Link>
                  </div>

                  <div className="mt-5">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        {errorMessage && (
                          <div className="alert alert-danger">{errorMessage}</div>
                        )}

                        <div className="card rounded p-4 shadow-lg">
                          <form onSubmit={handleSubmit}>
                            <div className="form-group"  style={{display: 'none'}}>
                              <label htmlFor="phone_numbers">Enter mobile numbers (comma separated):</label>
                              <textarea
                                className="form-control"
                                id="phone_numbers"
                                name="phone_numbers"
                                onChange={handleChange}
                                value={formData.phone_numbers}
                                placeholder="+254790021016, +254719146429"
                                rows={3}
                                readOnly
                                required
                               
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="message">Enter Message</label>
                              <textarea
                                className="form-control"
                                id="message"
                                name="message"
                                onChange={handleChange}
                                value={formData.message}
                                rows={5}
                                required
                              />
                            </div>

                            {isSubmitting ? (
                              <button
                                className="btn btn-primary w-100"
                                type="button"
                                disabled
                              >
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                                disabled={isSubmitting}
                              >
                                Send SMS
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SendBulkSMS;
