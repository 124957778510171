
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import { lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Profile from './components/User/Profile';


import Login from './pages/Login/Login';
import Triage from './common/Triage/Triage';
import Signup from './pages/Signup/Signup';
import Main from './common/Main';
import SignupInfo from './pages/Signup/SignupInfo';
import CreateProfile from './components/User/CreateProfile';
import PasswordReset from './components/PasswordReset/PasswordReset';
import PasswordResetConfirm from './components/PasswordReset/PasswordResetConfirm';
import PasswordResetRequest from './components/PasswordReset/PasswordResetRequest';
import PasswordResetResponse from './components/PasswordReset/PasswordResetResponse';
import UpdateProfile from './components/User/UpdateProfile';



function App() {
  return (
    <div className="App">
   
      <BrowserRouter>
     
       <Routes>
        <Route path='/' Component={Login} />
        <Route path='/triage' Component={Triage} />
        <Route path='/signup' Component={Signup } />
        <Route path='/main/*' Component={Main } />
        <Route path='/signupInfo' Component={SignupInfo} />
        <Route path='/create_profile' Component={CreateProfile}/>
        <Route path='/update_profile' Component={UpdateProfile} />
        <Route path='/reception_patient/userprofile' Component={Profile} />
        <Route path='/password_reset' element={<PasswordReset />}/>
        <Route path='/password_reset_confirm/:uidb64/:token' element={<PasswordResetConfirm />}/>
        <Route path='/password_reset_request' element={<PasswordResetRequest />}/>
        <Route path='/password_reset_response' element={<PasswordResetResponse />}/>
        
        
       </Routes>
      </BrowserRouter>
    
       
        
 
    </div>
  );
}

export default App;
