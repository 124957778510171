import React from "react";
import { useState, useEffect } from "react";
import { API_BASE_URL } from "../../apiConfig";
import DeleteConfirm from "../../common/DeleteConfirm";
import Spinner from "../../common/Spinner";
import DataTable from "react-data-table-component";

const AppConfig = () => {
  const userRole = localStorage.getItem("role");

  const [apps, setApps] = useState([]);
  const [selectedAppId, setSelectedAppId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [show, setShow] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getApps = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/app_config/app_configs/`);
      const data = await response.json();
      setApps(Array.isArray(data) ? data : []);
    } catch (error) {
      console.log("error while fetching counties");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getApps();
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleDelete = (appId) => {
    setSelectedAppId(appId);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }
  const filteredApps = apps.filter((app) => {
    return app.app_name.toLowerCase().includes(searchText.toLowerCase());
  });

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/app_config/app_configs/delete/${selectedAppId}/`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        getApps();
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("An error occured while deleting app name", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => `${row.app_name}`,
      sortable: true,
    },

    {
        name: "Email",
        selector: (row) => `${row.email}`,
        sortable: true,
      },

    {
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn  btn-sm text-xs"
            type="button"
            id={`dropdownMenuButton-${row.userid}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="fa fa-ellipsis-v"></span>
          </button>
          <div
            className="dropdown-menu text-xs"
            aria-labelledby={`dropdownMenuButton-${row.id}`}
          >
           
              <>
                <a className="text-white" href={`/main/app_config/${row.id}/update/`}>
                  <button className="dropdown-item">Edit</button>
                </a>

                <button
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDelete(row.id);
                    handleShow();
                  }}
                >
                  Delete
                </button>
              </>
          
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "60px", // Set a minimum width for the dropdown button
    },
  ];

  return (
    <div className="content-wrapper mt-2">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <section className="content-header  " id="connectedSortable">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>App Name</h1>
                  <DeleteConfirm />
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/main/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active">App Name</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header "></div>
                    <div className="card-body">
                      <div className="container mt-5">
                        <div className="container mt-5">
                          <div className="row">
                            <div className="col-sm-6">
                              <h3 className="card-title text-bold">
                                App Name 
                              </h3>
                              <p>(only one is required)</p>
                            </div>

                         
                            <div className="col-sm-6 text-right">
                           
                               <button
                               type="button"
                               className="button-primary"
                               style={{ float: "right" }}
                               onClick={() => {
                                 window.location.href = "/main/app_config/create";
                               }}
                             >
                               Add New
                             </button>
                        
                             
                       
                                <button
                                  type="button"
                                  className="button-primary-outline"
                                  style={{
                                    float: "right",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    const csv = apps.map((app) => ({
                                      ID: `${app.id}`,
                                      APP_NAME: app.app_name,
                                    }));
                                    const csvData = convertToCSV(csv);
                                    const blob = new Blob([csvData], {
                                      type: "text/csv",
                                    });
                                    const url =
                                      window.URL.createObjectURL(blob);
                                    const a = document.createElement("a");
                                    a.setAttribute("href", url);
                                    a.setAttribute("download", "users.csv");
                                    a.click();
                                  }}
                                >
                                  Export to CSV
                                </button>
                        
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search..."
                                value={searchText}
                                onChange={handleSearch}
                                style={{
                                  width: "200px",
                                  float: "right",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <DataTable
                            columns={columns}
                            data={filteredApps}
                            fixedHeader
                            responsive={true}
                            selectableRows
                            // progressPending={loading}
                            // onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                            striped // Enable row striping
                            highlightOnHover // Highlight row
                            pagination // Paginate
                          />
                        </div>
                      </div>

                      {show && (
                        <DeleteConfirm
                          handleConfirmDelete={handleConfirmDelete}
                          itemName={
                            apps.find((app) => app.id === selectedAppId)
                              ?.app_name
                          }
                          handleClose={handleClose}
                          handleShow={handleShow}
                          show={show}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default AppConfig;
