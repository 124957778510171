

import React, { useState } from 'react';
import { API_BASE_URL } from '../../apiConfig';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [errorMessage, setErrorMessage] = useState('');    
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_BASE_URL}/accounts/loginUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.role_name)

        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("user_role", data.role_name);
        localStorage.setItem('username', data.username);
        localStorage.setItem('userId', data.user_id);

        if (data.role_name === "admin" || data.role_name === 'doctor') {
          navigate('/main/dashboard');
          window.location.reload()
         
        } else {
          navigate('/reception_patient/userprofile');
         
        }
      } else {
        setErrorMessage("Invalid credentials. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-center mt-5 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-10">
            <div className="card rounded shadow-sm ">
              <div className="row ">
                <div className="col-md-6 d-none d-md-block">
                  <img
                    src="/lab.jpg"
                    alt="Medihub"
                    className="img-fluid rounded-left"
                    style={{ objectFit: "cover", height: "100%" }}
                  />
                </div>

                <div className="col-md-6 d-flex align-items-center">
                  <div className="card-body p-4">
                    <div className="text-center mb-4">
                      <img src="/NWHlogo.PNG" alt="Medihub"style={{width: "200px"}} />
                    </div>

                    <p className="text-center">Sign in to start your session</p>
                    <form onSubmit={handleSubmit} method="post" action="">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Username"
                          id="username"
                          name="username"
                          onChange={handleChange}
                          value={formData.username}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          name="password"
                          id="password"
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={handleChange}
                          value={formData.password}
                          required
                        />
                      </div>

                      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                      {isSubmitting ? (
                        <button class="btn btn-primary w-100" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>
                      ) : (
                        <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                        Sign in
                      </button>
                      )}

                     
                    </form>

                    <div className="text-center mt-3">
                      <a href="/password_reset" className="text-primary">I forgot my password</a>
                    </div>
                    <div className="text-center mt-2">
                      <a href="/signup" className="text-primary font-weight-bold">Don't have an account? Sign up</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;