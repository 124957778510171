import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../apiConfig";
import { Link, useParams } from "react-router-dom";

const ReceptionView = () => {
    const [patient, setPatient] = useState({})
    const [isLoading, setLoading] = useState(false)
   

    const params = useParams()


    const getReceptionPatient = async () => {
       
        try {
          const response = await fetch(`${API_BASE_URL}/reception/reception_patient/${params.id}/view/`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
    
          //   if(response.status === 401) {
          //     handleTokenExpiry()
          //     return
          //   }
    
          const data = await response.json();
          setPatient(data);
          console.log("Patients", data);
        } catch (error) {
          console.log("an error occurred while fetching patients");
        } finally {
          setLoading(false);
        }
      };



      useEffect(() => {
        getReceptionPatient()
      }, [params.id])

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"></div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/main/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Profile</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
                <div className="card-body">
                  

                  <div className="container mt-2">
                    <div className="row justify-content-center">
                        <div>
                            <h5>PROFILE FOR : {patient.first_name} {patient.last_name} </h5>
                        </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <img src="/EABL.png" alt="EABL logo" width={100} />

                        <img src="/NWHlogo.PNG" alt="Medihub logo" width={100} />
                      </div>
                      <div className=" col-12">
                       
                        <div className="card rounded p-3 shadow-lg">
                          <div>
                            <div className="row">

                                 {/* OOP Number */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">OOP Number</label>
                                <p>{patient.oop_number}</p>
                              </div>

                              {/* First Name */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">First Name</label>
                                <p>{patient.first_name}</p>
                              </div>

                               {/* Last Name */}
                               <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">Last Name</label>
                                <p>{patient.last_name}</p>
                              </div>

                              {/* Nationality */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="nationality">Nationality</label>
                                <p>{patient.nationality}</p>
                              </div>

                              {/* Gender */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="gender">Gender</label>
                                <p>{patient.sex}</p>
                              </div>

                              {/* Location */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="location">Location</label>
                                <p>{patient.location}</p>
                              </div>

                              {/* Next of kin */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="nextOfKin">Next of kin </label>
                                <p>{patient.next_of_kin}- ({patient.relationship})</p>
                              </div>

                              {/* Relationship */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="relationship">
                                  Relationship
                                </label>
                                <p>{patient.relationship}</p>
                              </div>

                              {/* Next of kin phone number */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="nextOfKinPhone">
                                  Next of Kin phone number
                                </label>
                                <p>{patient.next_of_kin_phone_number}</p>
                              </div>

                              {/* Department */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="department">Department</label>
                                <p>{patient.department}</p>
                              </div>

                              {/* Work location */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="workLocation">
                                  Work location
                                </label>
                                <p>{patient.worklocation}</p>
                              </div>

                              {/* Sub function */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="subFunction">
                                  Sub function
                                </label>
                                <p>{patient.subfunction}</p>
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="subFunction">Company</label>
                                <p>{patient.company}</p>
                              </div>

                                {/*Employer contact person  */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="employer_contact_person">Employer contact person</label>
                                <p>{patient.employer_contact_person}</p>
                              </div>

                                 {/*Employer contact   */}
                                 <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="employer_contact">Employer contact</label>
                                <p>{patient.employer_contact}</p>
                              </div>


                              {/* EABL COY Number */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="eablCoyNumber">
                                  EABL COY Number (EABL****)
                                </label>
                                <p>{patient.staff_number}</p>
                              </div>

                              {/* Spouse/Pricipal */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="Spouse/principal">
                                  Principal / Spouse
                                </label>
                                <p>{patient.principal? "PRINCIPAL" : "SPOUSE"}</p>
                              </div>

                                    {/* Status */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="Spouse/principal">
                                  Status
                                </label>
                                {patient.is_attended ? <p ><span className=" badge badge-success">attended</span></p> : <p><span className=" badge badge-warning text-dark">pending</span></p>}
                              </div>


                                {/* Date created */}
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="Spouse/principal">
                                  Date created
                                </label>
                                <p>{new Date(patient.date_created).toLocaleDateString()}</p>
                              </div>

                                {/* Date updated */}
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="Spouse/principal">
                                  Date updated
                                </label>
                                <p>{new Date(patient.date_updated).toLocaleDateString()}</p>
                              </div>
                            </div>

                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReceptionView;
