import React  from 'react';


const SignupInfo = () => {
  

  

  return (
    <div className="d-flex align-items-center justify-content-center  ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-5">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="text-center mb-4">
                  <img src="/NWHlogo.PNG" alt="Medihub" style={{width: "180px"}} />
                </div>
                <h5 className="text-primary font-weight-bold text-center mb-4">Congratulations for registering</h5>
                <p className=' text-center'>Please check your email  to activate your account</p>
                
                
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupInfo;
