import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import TableLoader from "../../common/TableLoader";
import Spinner from "../../common/Spinner";
import { API_BASE_URL } from "../../apiConfig";
import DeleteConfirm from "../../common/DeleteConfirm";
import { useParams } from "react-router-dom";
// import useTokenExpiration from "../../../useTokenExpiration";

const PatientsHistory = () => {
  const [patientsHistory, setSetPatientsHistory] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPatientHistoryId, setSelectedPatientHistoryId] = useState(null);
  const [userEmails, setUserEmails] = useState([])
  //   const { errorMessage, handleTokenExpiry } = useTokenExpiration();
  const params = useParams()
  const [show, setShow] = useState(false);

  //   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/reception/reception_patient/patients_attendance_history/${selectedPatientHistoryId}/delete/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      //   if(response.status === 401) {
      //     handleTokenExpiry()
      //     return
      //   }

      if (response.ok) {
        getReceptionPatientsHistory();
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("An error occured while deleting reception patient", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const handleDelete = (patientHistoryId) => {
    setSelectedPatientHistoryId(patientHistoryId);
  };

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }


  const getReceptionPatientsHistory = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/reception/reception_patients/patients_attendance_history/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

    

      const data = await response.json();
      setSetPatientsHistory(Array.isArray(data) ? data : []);
      console.log("History Data", data);
    } catch (error) {
      console.log("an error occurred while fetching patients");
    } finally {
      setLoading(false);
    }
  };


  const getUserEmails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/accounts/users/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

     
      const data = await response.json();
      setUserEmails(Array.isArray(data) ? data : []);
      console.log("Emails", data.map((email) => email.email));
    } catch (error) {
      console.log("an error occurred while fetching emails");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getUserEmails()
  }, [])

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredPatientsHistory = patientsHistory.filter((history) => {
    return (
      history.date?.toLowerCase().includes(searchText.toLowerCase())
     
    );
  });

  const columns = [
    {
      name: "Date Attended",
      selector: (row) => `${row.date} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "women count",
      selector: (row) => `${row.women_count} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "men count",
      selector: (row) => `${row.men_count} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Total count",
      selector: (row) => `${row.total_patients} `,
      sortable: true,
      className: "bold-header",
    },

    
    {
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn  btn-sm text-xs"
            type="button"
            id={`dropdownMenuButton-${row.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="fa fa-ellipsis-v"></span>
          </button>
          <div
            className="dropdown-menu text-xs"
            aria-labelledby={`dropdownMenuButton-${row.id}`}
          >
            <>
             

              <a className="text-white" href={`/main/patients/patients_history/${row.id}/view`}>
                <button className="dropdown-item ">View</button>
              </a>

              <button
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDelete(row.id);
                  handleShow();
                }}
              >
                Delete
              </button>
            </>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "60px", // Set a minimum width for the dropdown button
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      getReceptionPatientsHistory();
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="content-wrapper" style={{ minHeight: "90vh" }}>
          <section className="content-header ">
            <div className="container-fluid ">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h5>Reception patients History</h5>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/main/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Reception Patients History
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-body ">
                      <div className="container   mt-5">
                        <div className="row ">
                          <div className="col-sm-6">
                            <h3 className="card-title text-bold">
                              Reception Patients History
                            </h3>
                          </div>
                          <div className="col-sm-6 text-right ">
                            <button
                              type="button"
                              className="button-primary-outline"
                              style={{ float: "right", marginRight: "10px" }}
                              onClick={() => {
                                const csv = patientsHistory.map((history) => ({
                                  attendance_date: history.date,
                                  men_count: history.men_count,
                                  women_count: history.women_count,
                                  Total_attended: history.total_patients,
                                  
                                }));
                                const csvData = convertToCSV(csv);
                                const blob = new Blob([csvData], {
                                  type: "text/csv",
                                });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.setAttribute("href", url);
                                a.setAttribute(
                                  "download",
                                  "Patients history.csv"
                                );
                                a.click();
                              }}
                            >
                              Export to CSV
                            </button>

                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Search..."
                              value={searchText}
                              onChange={handleSearch}
                              style={{
                                width: "200px",
                                float: "right",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )} */}

                      <div className="container border rounded mt-5">
                        <div>
                          {loading}
                          <DataTable
                            columns={columns}
                            data={filteredPatientsHistory}
                            fixedHeader
                            responsive={true}
                            selectableRows
                            progressPending={loading}
                            progressComponent={<TableLoader />}
                            onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                            striped // Enable row striping
                            highlightOnHover // Highlight row
                            pagination // Paginate
                          />
                        </div>

                        {show && (
                          <DeleteConfirm
                            handleConfirmDelete={handleConfirmDelete}
                            itemName={
                              patientsHistory.find(
                                (history) => history.id === selectedPatientHistoryId
                              )?.date
                            }
                            handleClose={handleClose}
                            handleShow={handleShow}
                            show={show}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default PatientsHistory;
