import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../apiConfig";
import { Link, useNavigate } from "react-router-dom";

const CreateProfile = () => {
  const [formData, setFormData] = useState({
    staff_number: "",
    mobile_number: "+254",
    sex: "",
    nationality: "",
    location: "",
    relationship: "",
    next_of_kin: "",
    next_of_kin_phone_number: "",
    spouse: false,
    principal: false,
    company: "",
    department: "",
    worklocation: "",
    subfunction: "",
    employer_contact_person: "",
    employer_contact: "",

  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [departments, setDepartments] = useState([]);
  const [worklocations, setWorklocations] = useState([]);
  const [subfunctions, setSubfunctions] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedWorklocation, setSelectedWorklocation] = useState(null);
  const [selectedSubfunction, setSelectedSubfunction] = useState(null);


  const USERNAME = localStorage.getItem("username")

  const navigate = useNavigate();

  const getDepartments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/department/departments/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const data = await response.json();
      setDepartments(data);
      console.log(data);
    } catch (error) {
      console.log("error while fetching departments");
    }
  };

  const getWorklocations = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/department/worklocations/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      const data = await response.json();
      setWorklocations(data);
    } catch (error) {
      console.log("Error fetching worklocations:", error);
    }
  };

  const getSubfunctions = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/department/subfunctions/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const data = await response.json();
      setSubfunctions(data);
    } catch (error) {
      console.log("error occured while fetching subfunctions api data");
    }
  };

  useEffect(() => {
    getDepartments();
    getWorklocations();
    getSubfunctions();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    


    if (name === "department") {
      setSelectedDepartment(Number(value));
      getWorklocations();
      console.log("Work locations" ,worklocations)
      console.log("selected department ", selectedDepartment)
    } else if (name === "worklocation") {
      setSelectedWorklocation(Number(value));
      getSubfunctions();
      console.log("Sub Functions", subfunctions)
    } 


    if(name === "mobile_number") {
      let phoneNumber = value.startsWith("+254") ? value : `+254${value.replace(/^(\+?254)?/, '')}`;
      if (phoneNumber.startsWith("+2540")) {
        phoneNumber = phoneNumber.replace("+2540", "+2547");
      }

      if(phoneNumber.length <= 13) {
        setFormData ({
          ...formData,
          [name]: phoneNumber
        }) 
      }
    } else {

      setFormData({
        ...formData,
        [name]: value,
      });
    }

  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      spouse: value === "spouse",
      principal: value === "principal",
    });
  };




 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    try {
      const response = await fetch(
        `${API_BASE_URL}/accounts/patients/profile/create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        navigate("/reception_patient/userprofile");
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      console.log("An error occurred while submitting data");
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/main/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Profile</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
                <div className="card-body">
                <strong>Welcome {USERNAME}</strong>
                  <div className="d-flex justify-content-between mb-4">
                   
                    <h3 className="card-title">Create profile</h3>
                    <Link
                      to="/reception_patient/userprofile"
                      className="button-primary"
                      style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
                    >
                      view profile
                    </Link>
                    
                  </div>

                  <div className="container mt-5">
                    <div className="row justify-content-center">
                      <div className="d-flex align-items-center justify-content-between">
                        <img src="/EABL.png" alt="EABL logo" width={100} />

                        <img
                          src="/NWHlogo.PNG"
                          alt="Medihub logo"
                          width={100}
                        />
                      </div>
                      <div className=" col-12">
                        
                        <div className="card rounded p-3 shadow-lg">
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              {/* Mobile number */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="name">Mobile number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="mobile_number"
                                  name="mobile_number"
                                  value={formData.mobile_number}
                                  onChange={handleChange}
                                />
                              </div>

                              {/* Nationality */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="nationality">Nationality</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nationality"
                                  name="nationality"
                                  value={formData.nationality}
                                  onChange={handleChange}
                                />
                              </div>

                              {/* Gender */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="sex">Gender</label>
                                <select
                                  type="text"
                                  className="form-control"
                                  id="sex"
                                  name="sex"
                                  value={formData.sex}
                                  onChange={handleChange}
                                >
                                  <option value="">select gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="prefer not to say">
                                    Prefer not to say
                                  </option>
                                </select>
                              </div>

                              {/* Location */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="location">Location</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="location"
                                  name="location"
                                  value={formData.location}
                                  onChange={handleChange}
                                />
                              </div>

                              {/* Next of kin */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="next_of_kin">Next of kin</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="next_of_kin"
                                  name="next_of_kin"
                                  value={formData.next_of_kin}
                                  onChange={handleChange}
                                />
                              </div>

                              {/* Relationship */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="relationship">
                                  Relationship
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="relationship"
                                  name="relationship"
                                  value={formData.relationship}
                                  onChange={handleChange}
                                />
                              </div>

                              {/* Next of kin phone number */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="next_of_kin_phone_number">
                                  Next of Kin phone number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="next_of_kin_phone_number"
                                  name="next_of_kin_phone_number"
                                  value={formData.next_of_kin_phone_number}
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="role">Principal / Spouse</label>
                                <div className=" d-flex align-items-center gap-4">
                                  <div>
                                    <input
                                      type="radio"
                                      id="spouse"
                                      name="role"
                                      value="spouse"
                                      checked={formData.spouse}
                                      onChange={handleRadioChange}
                                    />
                                    <label htmlFor="spouse">Spouse</label>
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      id="principal"
                                      name="role"
                                      value="principal"
                                      checked={formData.principal}
                                      onChange={handleRadioChange}
                                    />
                                    <label htmlFor="principal">Principal</label>
                                  </div>
                                </div>
                              </div>

                              {/* Department */}

                              {formData.principal && (
                                <>
                                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label htmlFor="department">
                                      Department
                                    </label>

                                    <select
                                      type="text"
                                      className="form-control"
                                      id="department"
                                      name="department"
                                      value={formData.department}
                                      onChange={handleChange}
                                    >
                                      <option value="">
                                        Select Department
                                      </option>
                                      {departments.map((department) => (
                                        <option
                                          key={department.department_id}
                                          value={department.department_id}
                                        >
                                          {department.department_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label htmlFor="worklocation">
                                      Work location
                                    </label>

                                    <select
                                      type="text"
                                      className="form-control"
                                      id="worklocation"
                                      name="worklocation"
                                      value={formData.worklocation}
                                      onChange={handleChange}
                                    >
                                      <option value="">
                                        Select worklocation
                                      </option>

                                      {worklocations
                                        .filter(
                                          (worklocation) =>
                                            worklocation.department_id ===
                                            selectedDepartment
                                        )
                                        .map((worklocation) => (
                                          <option
                                            key={worklocation.worklocation_id}
                                            value={worklocation.worklocation_id}
                                          >
                                            {worklocation.worklocation_name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">

                                  <label htmlFor="subfunction">
                                  Sub function
                                    </label>

                                    <select
                                        type="text"
                                        className="form-control"
                                        id="subfunction"
                                        name="subfunction"
                                        value={formData.subfunction}
                                        onChange={handleChange}
                                    >
                                      <option value="">
                                        Select worklocation
                                      </option>

                                      {subfunctions
                                        .filter(
                                          (subfunction) =>
                                            subfunction.worklocation_id ===
                                            selectedWorklocation
                                        )
                                        .map((subfunction) => (
                                          <option
                                            key={subfunction.subfunctions_id}
                                            value={subfunction.subfunctions_id}
                                          >
                                            {subfunction.subfunctions_name}
                                          </option>
                                        ))}
                                    </select>


                                
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label htmlFor="company">Company</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="company"
                                      name="company"
                                      value={formData.company}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label htmlFor="employer_contact">
                                      Employer contact
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="employer_contact"
                                      name="employer_contact"
                                      value={formData.employer_contact}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label htmlFor="employer_contact_person">
                                      Employer contact person
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="employer_contact_person"
                                      name="employer_contact_person"
                                      value={formData.employer_contact_person}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </>
                              )}

                              {/* EABL COY Number */}
                              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label htmlFor="staff_number">
                                  EABL COY Number (EABL****)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="staff_number"
                                  name="staff_number"
                                  value={formData.staff_number}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            {errorMessage && (
                          <div className="alert alert-danger">
                            {errorMessage}
                          </div>
                        )}

                            {isSubmitting ? (
                              <button
                                className="btn btn-primary  w-fit"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-fit"
                                disabled={isSubmitting}
                              >
                                submit
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateProfile;
