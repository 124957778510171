import React, { Component } from "react";
import Header from "./Header";
import AsideControl from "./AsideControl";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ADMIN } from "../globals/roles";
import Dashboard from "../components/Dashbaord/Dashboard";

import RoleList from "../components/RolesList/RoleList";
import CreateRoles from "../components/RolesList/CreateRoles";
import AddUser from "../components/User/AddUser";
import UpdateRole from "../components/RolesList/UpdateRole";
import Menu from "./Menu";
import RegistredUsers from "../components/User/Reports/RegistredUsers";
import TodayRegistredUsers from "../components/User/Reports/TodayRegisteredUsers";
import CreateProfile from "../components/User/CreateProfile";
import Reception from "../components/Reception/Reception";
import ReceptionView from "../components/Reception/ReceptionView";
import PendingDoctor from "../components/Doctor/PendingDoctor";
import ClosedDoctorAttendance from "../components/Doctor/ClosedDoctorAttendance";
import PendingLab from "../components/Laboratory/PendingLab";
import ClosedLabAttendance from "../components/Laboratory/ClosedLabAttendance";
import AppConfig from "../components/AppConfig/AppConfig";
import CreateAppConfig from "../components/AppConfig/CreateAppConfig";
import UpdateAppConfig from "../components/AppConfig/UpdateAppConfig";
import EablStaff from "../components/EablStaff/EablStaff";
import EablStaffView from "../components/EablStaff/EablStaffView";
import AssignedRoles from "../components/AssignedRole/AssignedRoles";
import AssignRole from "../components/AssignedRole/AssignRole";
import DailyAttendedPatients from "../components/Reception/DailyAttendedPatients";
import UpdateAssignRole from "../components/AssignedRole/UpdateAssignedRole";
import Messages from "../components/Messages/Messages";
import Message from "../components/Messages/Message";
import SendSMS from "../components/Messages/SendSMS";
import SendBulkSMS from "../components/Messages/SendBulkSMS";
import SMS from "../components/Messages/SMS";
import PatientsHistory from "../components/Reception/PatientsHistory";
import PatientHistoryView from "../components/Reception/PatientHistoryView";
import UpdateProfileByAdmin from "../components/User/UpdateProfileByAdmin";
import ViewProfileByAdmin from "../components/User/ViewProfileByAdmin";

const Main = () => {
  const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
    const USERROLE = localStorage.getItem("user_role");

    if (roles.includes(USERROLE)) {
      return <Component {...rest} />;
    } else {
      return <Navigate to={"/"} replace />;
    }
  };

  return (
    <div>
      <Menu />
      <Header />

      <Routes>
        <Route
          path="/dashboard"
          element={<ProtectedRoute component={Dashboard} roles={`${ADMIN}`} />}
        />

        <Route
          path="/eabl_staff"
          element={<ProtectedRoute component={EablStaff} roles={`${ADMIN}`} />}
        />

        <Route
          path="/eabl_staff/:id"
          element={
            <ProtectedRoute component={EablStaffView} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/app_config"
          element={<ProtectedRoute component={AppConfig} roles={`${ADMIN}`} />}
        />

        <Route
          path="/app_config/create"
          element={
            <ProtectedRoute component={CreateAppConfig} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/app_config/:id/update"
          element={
            <ProtectedRoute component={UpdateAppConfig} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/assigned_roles"
          element={
            <ProtectedRoute component={AssignedRoles} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/assign_role"
          element={<ProtectedRoute component={AssignRole} roles={`${ADMIN}`} />}
        />

        <Route
          path="/update_assigned_role/:id"
          element={
            <ProtectedRoute component={UpdateAssignRole} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/role-list"
          element={<ProtectedRoute component={RoleList} roles={`${ADMIN}`} />}
        />
        <Route
          path="/add_role"
          element={
            <ProtectedRoute component={CreateRoles} roles={`${ADMIN}`} />
          }
        />
        <Route
          path="/add_user"
          element={<ProtectedRoute component={AddUser} roles={`${ADMIN}`} />}
        />
        <Route
          path="/update_role/:id"
          element={<ProtectedRoute component={UpdateRole} roles={`${ADMIN}`} />}
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute component={RegistredUsers} roles={`${ADMIN}`} />
          }
        />
        <Route
          path="/today_registered_users"
          element={
            <ProtectedRoute
              component={TodayRegistredUsers}
              roles={`${ADMIN}`}
            />
          }
        />

        <Route
          path="/daily_attended_patients"
          element={
            <ProtectedRoute
              component={DailyAttendedPatients}
              roles={`${ADMIN}`}
            />
          }
        />
        <Route
          path="/create_profile"
          element={
            <ProtectedRoute component={CreateProfile} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/update_profile/:id/update"
          element={
            <ProtectedRoute
              component={UpdateProfileByAdmin}
              roles={`${ADMIN}`}
            />
          }
        />

        <Route
          path="/profile/:id/view"
          element={
            <ProtectedRoute component={ViewProfileByAdmin} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/reception_patients"
          element={<ProtectedRoute component={Reception} roles={`${ADMIN}`} />}
        />
        <Route
          path="/reception_patient/:id/view"
          element={
            <ProtectedRoute component={ReceptionView} roles={`${ADMIN}`} />
          }
        />
        <Route
          path="/reception_patients/pending_doctor"
          element={
            <ProtectedRoute component={PendingDoctor} roles={`${ADMIN}`} />
          }
        />
        <Route
          path="/reception_patients/closed_doctor_attendance"
          element={
            <ProtectedRoute
              component={ClosedDoctorAttendance}
              roles={`${ADMIN}`}
            />
          }
        />
        <Route
          path="/reception_patients/pending_lab_attendance"
          element={<ProtectedRoute component={PendingLab} roles={`${ADMIN}`} />}
        />
        <Route
          path="/reception_patients/closed_lab_attendance"
          element={
            <ProtectedRoute
              component={ClosedLabAttendance}
              roles={`${ADMIN}`}
            />
          }
        />

        <Route
          path="/sms/messages"
          element={<ProtectedRoute component={Messages} roles={`${ADMIN}`} />}
        />

        <Route
          path="/sms/send_sms"
          element={<ProtectedRoute component={SendSMS} roles={`${ADMIN}`} />}
        />

        <Route
          path="/sms/send_sms_bulk"
          element={
            <ProtectedRoute component={SendBulkSMS} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/sms/select_mode"
          element={<ProtectedRoute component={SMS} roles={`${ADMIN}`} />}
        />

        <Route
          path="/sms/message/:id"
          element={<ProtectedRoute component={Message} roles={`${ADMIN}`} />}
        />

        <Route
          path="/patients/patients_history"
          element={
            <ProtectedRoute component={PatientsHistory} roles={`${ADMIN}`} />
          }
        />

        <Route
          path="/patients/patients_history/:id/view"
          element={
            <ProtectedRoute component={PatientHistoryView} roles={`${ADMIN}`} />
          }
        />
      </Routes>
    </div>
  );
};

export default Main;
