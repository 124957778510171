import React, { useState } from 'react';
import { API_BASE_URL } from '../../apiConfig';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone_number: "+254",
    password: "",
    password2: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    
    if (formData.password !== formData.password2) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    setIsSubmitting(true); 

    try {
      const response = await fetch(`${API_BASE_URL}/accounts/registerUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log("User created successfully");
        navigate('/signupInfo');
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      console.log("An error occurred while submitting data");
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(name === "phone_number") {
      let phoneNumber = value.startsWith("+254") ? value : `+254${value.replace(/^(\+?254)?/, '')}`;
      if (phoneNumber.startsWith("+2540")) {
        phoneNumber = phoneNumber.replace("+2540", "+2547");
      }

      if(phoneNumber.length <= 13) {
        setFormData ({
          ...formData,
          [name]: phoneNumber
        }) 
      }
     
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    }

  return (
    <div className="d-flex align-items-center justify-content-center mt-5 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-5">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="text-center mb-4">
                  <img src="/NWHlogo.PNG" alt="Medihub" style={{width: "150px"}}/>
                </div>
                <h5 className="text-primary font-weight-bold text-center mb-4">Create Account</h5>
               
               
                <form onSubmit={handleSubmit}>
                  <div style={{lineHeight: "1px"}}>
                  <p className="text-md " style={{color: 'gray'}}>User name</p>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      
                      id="username"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  </div>


                  <div style={{lineHeight: "1px"}}>
                  <p className="text-md " style={{color: 'gray'}}>First name</p>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                    
                      id="first_name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  </div>
                  
                 <div style={{lineHeight: "1px"}}>
                 <p className="text-md " style={{color: 'gray'}}>Last name</p>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                     
                      id="last_name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                 </div>
                 <div style={{lineHeight: "1px"}}>
                  <p className="text-md " style={{color: 'gray'}}>Email address</p>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                     
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  </div>
                  <div style={{lineHeight: "1px"}}>
                  <p className="text-md " style={{color: 'gray'}}>Phone number</p>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                     
                      id="phone_number"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  </div>
                  <div style={{lineHeight: "1px"}}>
                  <p className="text-md " style={{color: 'gray'}}>Create password</p>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                     
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  </div>

                  <div style={{lineHeight: "1px"}}>
                  <p className="text-md " style={{color: 'gray'}}>Re-enter passsword</p>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                  
                      id="password2"
                      name="password2"
                      value={formData.password2}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  </div>

                  {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                  {isSubmitting ? (
                    <button className="btn btn-primary w-100" type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign up
                    </button>
                  )}
                </form>
                <div className="text-center mt-3">
                  <a href="/password_reset" className="text-primary">
                    I forgot my password
                  </a>
                </div>
                <div className="text-center mt-2">
                  <a href="/" className="text-primary font-weight-bold">
                    Have an account? Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
