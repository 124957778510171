import React from 'react'
import CountryAnalysis from '../DashboardCharts/CountryAnalysis'
import OrdersChart from '../DashboardCharts/OrdersChart'
import CityChart from '../DashboardCharts/CityChart'
import CompanyAnalysisChart from '../DashboardCharts/CompanyAnalysisChart'
import DashboardCard from '../DashboardCharts/DashboardCard'

const Dashboard = () => {

  const medicalData = [
    { id: 1, title: 'Patients Treated', value: 1200 },
    { id: 2, title: 'Lab Tests Conducted', value: 750 },
    { id: 3, title: 'Surgeries Performed', value: 1000 },
    { id: 4, title: 'Pending Appointments', value: 1500 },
   
  ];


  return (
    <div>
        <div className="content-wrapper" style={{ minHeight: "90vh" }}>
<section className="content-header ">
<div className="container-fluid ">
  <div className="row mb-2">
    <div className="col-sm-6">
      <h5>Dashbaord </h5>
    </div>
    <div className="col-sm-6">
      <ol className="breadcrumb float-sm-right">
        <li className="breadcrumb-item">
          <a href="/main/dashboard">Home</a>
        </li>
        <li className="breadcrumb-item active">Dashboard </li>
      </ol>
    </div>
  </div>
</div>
</section>
<section className="content">
  <div className="container-fluid">
 
  <div className="row">
      {medicalData.map((data) => (
        <div key={data.id} className="col-12 col-sm-6 col-lg-3 mb-2">
          <DashboardCard title={data.title} value={data.value} />
        </div>
      ))}
    </div>
   
    
    <div className="row">
      <div className="col-md-6">
        <CountryAnalysis />
        <OrdersChart />
      </div>

      <div className="col-md-6">
        
        <CityChart />
        <CompanyAnalysisChart />
      </div>
    </div>
   
    
  </div>
</section>
</div>

    </div>
  )
}

export default Dashboard