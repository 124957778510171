import React, { useState } from "react";
import { API_BASE_URL } from "../../apiConfig";
import { Link, useNavigate } from "react-router-dom";

const SendSMS = () => {
  const [formData, setFormData] = useState({
    phone_number: "+254",
    message: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_BASE_URL}/sms/send_sms/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Role created successfully");
        navigate("/main/sms/messages");
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      console.log("An error occurred while submitting data");
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;



    if(name === "phone_number") {
        let phoneNumber = value.startsWith("+254") ? value : `+254${value.replace(/^(\+?254)?/, '')}`;
        if (phoneNumber.startsWith("+2540")) {
          phoneNumber = phoneNumber.replace("+2540", "+2547");
        }
  
        if(phoneNumber.length <= 13) {
          setFormData ({
            ...formData,
            [name]: phoneNumber
          }) 
        }

    } else {
        setFormData({
            ...formData,
            [name]: value,
          });
    }
   
  };

  return (
    <div
      className="content-wrapper"
      style={{ minHeight: "90vh"}}
    >
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5 className="text-primary">Send sms</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/main/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Send sms</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4">
                    <h3 className="card-title text-primary">Send sms</h3>
                    <Link
                      to="/main/sms/messages"
                      className="button-primary"
                      style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
                    >
                      Back
                    </Link>
                  </div>

                
                  <div className=" mt-5">
                    <div className="row justify-content-center">
                      <div className="col-12">
                      {errorMessage && (
                    <div className="alert alert-danger">{errorMessage}</div>
                  )}
                        <div className="card rounded p-4 shadow-lg">
                          <form onSubmit={handleSubmit} method="post" action="">
                            <div className="form-group">
                              <label htmlFor="role">Enter mobile number :</label>
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Role Name"
                                id="phone_number"
                                name="phone_number"
                                onChange={handleChange}
                                value={formData.phone_number}
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="role">Enter Message</label>
                              <textarea
                                type="text"
                                className="form-control"
                                // placeholder="Role Name"
                                id="message"
                                name="message"
                                onChange={handleChange}
                                value={formData.message}
                                required
                                rows={5}
                              />

                            </div>

                            {isSubmitting ? (
                              <button
                                className="btn btn-primary w-100"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                                disabled={isSubmitting}
                              >
                                send SMS
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SendSMS;
