import React, { useState } from 'react'
import SendBulkSMS from './SendBulkSMS'
import SendSMS from './SendSMS'

const SMS = () => {
    const [activeComponent, setActiveComponent] = useState('individual');

    const handleShowIndividualSMS = () => {
        setActiveComponent('individual');
      };

      
      const handleShowBulkSMS = () => {
        setActiveComponent('bulk');
      };

  return (
      <div>
      <div className="container m-auto d-flex items-center gap-2 my-3">
 
        <button
          className={`button-primary ${activeComponent === 'individual' ? 'active' : ''}`}
          style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
          onClick={handleShowIndividualSMS}
        >
          Send Individual SMS
        </button>
        <button
          className={`button-primary ${activeComponent === 'bulk' ? 'active' : ''}`}
          style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
          onClick={handleShowBulkSMS}
        >
          Send Bulk SMS
        </button>
      </div>

   
      {activeComponent === 'individual' && <SendSMS />}
      {activeComponent === 'bulk' && <SendBulkSMS />}
    </div>


  )
}

export default SMS
