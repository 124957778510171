import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../apiConfig";
import { Link, useNavigate } from "react-router-dom";

const AssignRole = () => {
  const [formData, setFormData] = useState({
    role: "",
    user: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const getUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/accounts/users/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      const data = await response.json();
      setUsers(data);
      console.log("Users", data);
    } catch (error) {
      console.log("an error occurred while fetching patients");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_BASE_URL}/accounts/assign_role/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Role created successfully");
        navigate("/main/assigned_roles");
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      console.log("An error occurred while submitting data");
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5 className="text-primary">Assign Role</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/main/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Assign Role</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4">
                    <h3 className="card-title text-primary">Assign Role</h3>
                    <Link
                      to="/main/assigned_roles"
                      className="button-primary"
                      style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
                    >
                      Back
                    </Link>
                  </div>

                  <div className="container mt-5">
                    <div className="row justify-content-center">
                      <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                        {errorMessage && (
                          <div className="alert alert-danger">
                            {errorMessage}
                          </div>
                        )}
                        <div className="card rounded p-4 shadow-lg">
                          <form onSubmit={handleSubmit} method="post" action="">
                           

                            <div className="form-group">
                              <label htmlFor="role">Role</label>
                              <select
                                className="form-control"
                                id="role"
                                name="role"
                                onChange={handleChange}
                                value={formData.role}
                                required
                              >
                                <option value="">Select a Role</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                                <option value="receptionist">Receptionist</option>
                                <option value="doctor">Doctor</option>
                              </select>
                            </div>



                            <div className="form-group">
                              <label htmlFor="role">User</label>
                              <select
                                className="form-control"
                                id="user"
                                name="user"
                                onChange={handleChange}
                                value={formData.user}
                                required
                              >

                                <option value="">Select a Role</option>
                                {users.map((user, index) => (
                                    <option key={index} value={user.id}>{user.username}</option>
                                ))}
                                
                              </select>
                            </div>


                            

                            {isSubmitting ? (
                              <button
                                className="btn btn-primary w-100"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                                disabled={isSubmitting}
                              >
                                Add Role
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssignRole;
