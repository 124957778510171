import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import TableLoader from "../../common/TableLoader";
import Spinner from "../../common/Spinner";
import { API_BASE_URL } from "../../apiConfig";
import DeleteConfirm from "../../common/DeleteConfirm";
import { useParams } from "react-router-dom";
// import useTokenExpiration from "../../../useTokenExpiration";

const PatientHistoryView = () => {
  const [patients, setPatients] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [userEmails, setUserEmails] = useState([])
  const [dateAttended, setDateAttended] = useState(null)
  //   const { errorMessage, handleTokenExpiry } = useTokenExpiration();

  const [show, setShow] = useState(false);
  const params = useParams()

  //   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/reception/reception_patient/${selectedPatientId}/delete/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      //   if(response.status === 401) {
      //     handleTokenExpiry()
      //     return
      //   }

      if (response.ok) {
        getReceptionPatients();
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("An error occured while deleting reception patient", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const handleDelete = (patientId) => {
    setSelectedPatientId(patientId);
  };

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const AttendPatient = async (patientId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/reception/reception_patient/${patientId}/update_status/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ is_attended: true , is_principal: true}),
        }
      );

      if (response.ok) {
        getReceptionPatients();
      } else {
        throw Error("Falied to update status , please try again");
      }
    } catch (error) {
      console.log("Server error");
    }
  };

  const getReceptionPatients = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/reception/reception_patient/get_attendance_history/${params.id}/view`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

    

      const data = await response.json();
      setPatients(Array.isArray(data.patients) ? data.patients : []);
      setDateAttended(data.date)
      console.log("Patients", data);
    } catch (error) {
      console.log("an error occurred while fetching patients");
    } finally {
      setLoading(false);
    }
  };


  const getUserEmails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/accounts/users/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

     
      const data = await response.json();
      setUserEmails(Array.isArray(data) ? data : []);
      console.log("Emails", data.map((email) => email.email));
    } catch (error) {
      console.log("an error occurred while fetching emails");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getUserEmails()
  }, [])

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredReceptionPatients = patients.filter((patient) => {
    return (
      patient.oop_number?.toLowerCase().includes(searchText.toLowerCase()) ||
      patient.first_name
        ?.toLowerCase()
        .includes(searchText.toLocaleLowerCase()) ||
      patient.last_name
        ?.toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase()) ||

        patient.date_updated.toString().toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()) ||    
      patient.staff_number
        ?.toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase())
    );
  });

  const columns = [
    {
      name: "OOP number ",
      selector: (row) => `${row.oop_number} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "First name",
      selector: (row) => `${row.first_name} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Last name",
      selector: (row) => `${row.last_name} `,
      sortable: true,
      className: "bold-header",
    },

    // {
    //   name: "Mobile number",
    //   selector: (row) => `${row.mobile_number} `,
    //   sortable: true,
    //   className: "bold-header",
    // },

    {
      name: "Email address",
      selector: (row) => {
        const userEmail = userEmails.find((user) => user.id === row.user)?.email; 
        return userEmail || row.email || "N/A";
      },
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Staff number",
      selector: (row) => `${row.staff_number} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Date attended",
      selector: (row) => `${new Date(row.date_updated).toLocaleDateString()} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "status",
      selector: (row) =>
        row.is_attended ? (
          <span className=" badge badge-success ">attended</span>
        ) : (
          <span className="badge badge-warning text-dark rounded">pending</span>
        ),
      sortable: true,
      className: "bold-header",
    },

    {
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn  btn-sm text-xs"
            type="button"
            id={`dropdownMenuButton-${row.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="fa fa-ellipsis-v"></span>
          </button>
          <div
            className="dropdown-menu text-xs"
            aria-labelledby={`dropdownMenuButton-${row.id}`}
          >
            <>
              {!row.is_attended && (
                <button
                  className="dropdown-item"
                  onClick={() => AttendPatient(row.id)}
                >
                  mark as attended
                </button>
              )}

              <a className="text-white" href={`/main/reception_patient/${row.id}/view`}>
                <button className="dropdown-item ">View</button>
              </a>

              <button
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDelete(row.id);
                  handleShow();
                }}
              >
                Delete
              </button>
            </>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "60px", // Set a minimum width for the dropdown button
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      getReceptionPatients();
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="content-wrapper" style={{ minHeight: "90vh" }}>
          <section className="content-header ">
            <div className="container-fluid ">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h5>Patients History view</h5>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/main/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                    Patients History view
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-body ">
                      <div className="container   mt-5">
                        <div className="row ">
                          <div className="col-sm-6 d-flex align-items-center gap-4">
                            <h3 className="card-title text-bold ">
                            Patients History view
                          
                            </h3>

                            <h6 className="text-bold">{dateAttended}</h6>
                          </div>
                          <div className="col-sm-6 text-right ">
                            <button
                              type="button"
                              className="button-primary-outline"
                              style={{ float: "right", marginRight: "10px" }}
                              onClick={() => {
                                const csv = patients.map((patient) => ({
                                  oop_number: patient.oop_number,
                                  first_name: patient.first_name,
                                  last_name: patient.last_name,
                                  mobile_number: patient.mobile_number,
                                  email: userEmails.find((user) => user.id === patient.user)?.email || "N/A",
                                  gender: patient.sex,
                                  nationality: patient.nationality,
                                  location: patient.location,
                                  staff_number: patient.staff_number,
                                  department: patient.department,
                                  worklocation: patient.worklocation,
                                  subfunction: patient.subfunction,
                                }));
                                const csvData = convertToCSV(csv);
                                const blob = new Blob([csvData], {
                                  type: "text/csv",
                                });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.setAttribute("href", url);
                                a.setAttribute(
                                  "download",
                                  "Reception patients.csv"
                                );
                                a.click();
                              }}
                            >
                              Export to CSV
                            </button>

                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Search..."
                              value={searchText}
                              onChange={handleSearch}
                              style={{
                                width: "200px",
                                float: "right",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )} */}

                      <div className="container border rounded mt-5">
                        <div>
                          {loading}
                          <DataTable
                            columns={columns}
                            data={filteredReceptionPatients}
                            fixedHeader
                            responsive={true}
                            selectableRows
                            progressPending={loading}
                            progressComponent={<TableLoader />}
                            onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                            striped // Enable row striping
                            highlightOnHover // Highlight row
                            pagination // Paginate
                          />
                        </div>

                        {show && (
                          <DeleteConfirm
                            handleConfirmDelete={handleConfirmDelete}
                            itemName={
                              patients.find(
                                (patient) => patient.id === selectedPatientId
                              )?.first_name
                            }
                            handleClose={handleClose}
                            handleShow={handleShow}
                            show={show}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default PatientHistoryView;
