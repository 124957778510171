import React from 'react';
import ReactApexChart from 'react-apexcharts';

const CompanyAnalysisChart = () => {
  const series = [20, 35];
  const labels = [
    'Males',
    'Females ',
   
  ]; 

  const options = {
    chart: {
      type: 'donut',
      height: 400,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%', 
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              color: '#555',
              offsetY: 10,
            },
            value: {
              show: true,
              fontSize: '14px',
              color: '#333',
              offsetY: -10,
              formatter: function (val) {
                return val + '%';
              }
            },
            total: {
              show: true,
              label: 'Total',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0) + '%';
              }
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: 'bottom',
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + '%';
        }
      }
    },
    colors: ['#FF4560', '#00E396'],
    labels: labels,
  };

  return (
    <div className='border rounded bg-white shadow-lg rounded-md p-4'>
      <h4 className='text-sm text-bold'>Clinical Gender analysis</h4>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="donut" height={350} />
      </div>
    </div>
  );
};

export default CompanyAnalysisChart;
