import React from "react";
import { Link } from "react-router-dom";
import { IoSettings ,IoFastFoodSharp} from "react-icons/io5";
import { FaListUl , FaBrain, FaHospital, FaUserCheck, FaUserClock} from "react-icons/fa";
import { PiFilesBold } from "react-icons/pi";
import { TbReport, TbWheel, TbFileReport } from "react-icons/tb";
import { GiCartwheel, GiBrainDump } from "react-icons/gi";
import { MdEmojiFoodBeverage } from "react-icons/md";
import { FaUserDoctor, FaFolderOpen, FaFolderClosed, FaHospitalUser, FaUserAstronaut, FaHouseChimney } from "react-icons/fa6";

const Menu = () => {

  const sidebarFont = " text-dark "

  return (
    <div>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar bg-white sidebar-collapse elevation-4 mb-4">
        {/* Brand Logo */}
        <a href="index3.html" className="brand-link">
         
          <span className="brand-text font-weight-bold ">
            <img
            src="/NWHlogo.png"
            className="text-white"
            width={120}
            />
          </span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav style={{ overflowX: "hidden" }} className="mt-2">
            <ul
              className="nav nav-pills  nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item ">
                <Link
                  to="/main/dashboard"
                  className="nav-link "
                >
                  <i className="fa fa-th-large nav-icon fa-xs " />
                  <p className={sidebarFont}>Dashboard</p>
                </Link>
              </li>
              

              <li className="nav-item">
                <li className="nav-link">
                <FaHouseChimney size={20} className="mr-3" />
                  <p className={sidebarFont}>
                    Receptionist
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </li>
                <ul className="nav nav-treeview mx-2">
                  <li className="nav-item">
                    <Link to="/main/eabl_staff" className="nav-link">
                    <FaUserCheck size={18} className="mr-3" />
                      <p className={sidebarFont}>Eabl Staff</p>
                    </Link>

                    {/* <li className="nav-item">
                      <a href="/add_user" className="nav-link">
                      <FaUserClock size={18} className="mr-3" />
                        <p>Add User</p>
                      </a>
                    </li> */}

                 
                    <li className="nav-item">
                      <Link to="/main/reception_patients" className="nav-link">
                      <FaHospitalUser size={18} className="mr-3" />
                        <p className={sidebarFont}>View Patients</p>
                      </Link>
                    </li>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link to="/main/reception_patients" className="nav-link">
                <FaHospitalUser size={20} className="mr-3" />
                  <p className={sidebarFont}>
                    Patient
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview mx-2">
                  <li className="nav-item">
                  <Link to="/main/reception_patients" className="nav-link">
                  <FaUserAstronaut size={18} className="mr-3" />
                      <p className={sidebarFont}>View Patient</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li class="nav-item">
                <Link to="/main/reception_patients" class="nav-link">
                  <FaHospital size={18} className="mr-3" />
                  <p className={sidebarFont}>
                    Laboratory
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                  <Link to="/main/reception_patients/pending_lab_attendance" className="nav-link">
                  <FaFolderOpen size={18} className="mr-3" />
                      <p className={sidebarFont}>Pending laboratory</p>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link to="/main/reception_patients/closed_lab_attendance" class="nav-link">
                    <FaFolderClosed size={18} className="mr-3" />
                      <p className={sidebarFont}>Closed</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link to="/main/reception_patients" className="nav-link">
                <FaUserDoctor size={20} className="mr-3" />
                  <p className={sidebarFont}>
                    Doctor
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview mx-2">
                  <li className="nav-item">
                    <Link
                      to="/main/reception_patients/pending_doctor"
                      className="nav-link"
                    >
                      <FaFolderOpen size={18} className="mr-3" />
                      <p className={sidebarFont}>Pending Doctor</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/main/reception_patients/closed_doctor_attendance" className="nav-link">
                    <FaFolderClosed size={18} className="mr-3" />
                      <p className={sidebarFont}>Closed</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link to="/main/reception_patients" className="nav-link">
                  <i className="nav-icon fas fa-table"></i>
                  <p className={sidebarFont}>
                    Pharmacy
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/main/reception_patients" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p className={sidebarFont}>X-Ray</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/main/reception_patients" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p className={sidebarFont}>Intansive Care Unit</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link to="/main/reception_patients" className="nav-link">
                <IoFastFoodSharp size={20} className=" mr-3" />
                  <p className={sidebarFont}>
                    Nutrition
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview mx-2">
                  <li className="nav-item">
                    <Link to="/main/reception_patients" className="nav-link">
                    <MdEmojiFoodBeverage size={18} className=" mr-3" />
                      <p className={sidebarFont}>view Nutrition</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link to="/main/reception_patients" className="nav-link">
                <FaBrain size={20} className=" mr-3" />
                  <p className={sidebarFont}>
                    Psycology
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview mx-2">
                  <li className="nav-item">
                    <Link to="/main/psycology" className="nav-link">
                      <GiBrainDump size={18} className="mr-3" />
                      <p className={sidebarFont}>view psycology</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link to="/main/reception_patients" className="nav-link">
                <TbWheel size={20} className=" mr-3" />
                  <p className={sidebarFont}>
                    Wellness
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview mx-2">
                  <li className="nav-item">
                    <Link to="/main/reception_patients" className="nav-link">
                    <GiCartwheel size={18} className=" mr-3"  />
                      <p className={sidebarFont}>Wellness Wheel</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li class="nav-item">
                <Link to="/main/reception_patients" class="nav-link">
                  <PiFilesBold size={20} className=" mr-3" />
                  <p className={sidebarFont}>
                    Reports
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview mx-2">
                  <li className="nav-item">
                    <Link to="/main/users" className="nav-link">
                      <TbReport size={18} className="mr-3" />
                      <p className={sidebarFont}>Registered users</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/main/today_registered_users" className="nav-link">
                    <TbFileReport size={18} className="mr-3" />
                      <p className={sidebarFont}>Daily registred users</p>
                    </Link>
                  </li>


                  <li className="nav-item">
                    <Link to="/main/daily_attended_patients" className="nav-link">
                    <TbFileReport size={18} className="mr-3" />
                      <p className={sidebarFont}>Daily attended patients</p>
                    </Link>
                  </li>


                  <li className="nav-item">
                    <Link to="/main/patients/patients_history" className="nav-link">
                    <TbFileReport size={18} className="mr-3" />
                      <p className={sidebarFont}>Patients' attendance history</p>
                    </Link>
                  </li>
                </ul>
              </li>


              <li className="nav-item">
                <Link to="/main/reception_patients" className="nav-link">
                <FaUserDoctor size={20} className="mr-3" />
                  <p className={sidebarFont}>
                    SMS Records
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview mx-2">
                  <li className="nav-item">
                    <Link
                      to="/main/sms/messages"
                      className="nav-link"
                    >
                      <FaFolderOpen size={18} className="mr-3" />
                      <p className={sidebarFont}>Messages</p>
                    </Link>
                  </li>
                  
                </ul>
              </li>


              <li className="nav-item">
                <Link to="/main/reception_patients" class="nav-link">
                <IoSettings size={20} className=" mr-3" />
                  <p className={sidebarFont}>
                    Settings
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item mx-2">
                    <Link to="/main/role-list" className="nav-link">
                    <FaListUl size={18} className=" mr-3" />
                      <p className={sidebarFont}>Roles List</p>
                    </Link>
                  </li>

                  <li className="nav-item mx-2">
                    <a href="/main/assigned_roles" className="nav-link">
                    <FaListUl size={18} className=" mr-3" />
                      <p className={sidebarFont}>Assigned Roles </p>
                    </a>
                  </li>


                  <li className="nav-item">
                    <Link to="/main/app_config" className="nav-link">
                      <i className="far fa-user nav-icon"></i>
                      <p className={sidebarFont}>App Config </p>
                    </Link>
                  </li>
                </ul>
              </li>


              

            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
};

export default Menu;


