import React, { useState } from "react";
import { API_BASE_URL } from "../../apiConfig";
import { Link, useNavigate } from "react-router-dom";

const CreateRoles = () => {
  const [formData, setFormData] = useState({
    name: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_BASE_URL}/accounts/create_role/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Role created successfully");
        navigate("/main/role-list");
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      console.log("An error occurred while submitting data");
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div
      className="content-wrapper"
      style={{ minHeight: "90vh"}}
    >
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5 className="text-primary">Add Role</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/main/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Add Role</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4">
                    <h3 className="card-title text-primary">Add Role</h3>
                    <Link
                      to="/main/role-list"
                      className="button-primary"
                      style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
                    >
                      Back
                    </Link>
                  </div>

                
                  <div className="container mt-5">
                    <div className="row justify-content-center">
                      <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                      {errorMessage && (
                    <div className="alert alert-danger">{errorMessage}</div>
                  )}
                        <div className="card rounded p-4 shadow-lg">
                          <form onSubmit={handleSubmit} method="post" action="">
                            <div className="form-group">
                              <label htmlFor="role">Role Name</label>
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Role Name"
                                id="name"
                                name="name"
                                onChange={handleChange}
                                value={formData.name}
                              />
                            </div>

                            {isSubmitting ? (
                              <button
                                className="btn btn-primary w-100"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                                disabled={isSubmitting}
                              >
                                Add Role
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateRoles;
