import React from 'react';

const DashboardCard = ({ title, value }) => {
  return (
    <div className='border rounded bg-white shadow-lg rounded-md mb-2 p-4'>
      <h3 className="text-sm font-bold">{title}</h3>
      <p style={{fontWeight: "bold", fontSize: "40px"}}>{value}</p>
    </div>
  );
};

export default DashboardCard;
