import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../apiConfig';

const CreateAppConfig = () => {
  const navigate = useNavigate();

  const [appName, setAppName] = useState({
    app_name: '',
    email: '',
    login_url: '',
    signup_url: '',
    email_sender: '',
    email_password: '',
    backend_base_url: '',

    username: "",
    api_key: "",
    sender: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAppName({
      ...appName,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/app_config/app_configs/create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(appName)
      });

      if (response.ok) {
        console.log('App configuration created successfully');
        navigate('/main/app_config');
        window.location.reload();
      } else {
        console.log('Error occurred while submitting data to the server');
      }
    } catch (error) {
      console.log('An error occurred while submitting data');
    }
  };

  return (
    <div style={{ minHeight: '90vh' }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"></div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">App Config</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
              <h3 className="card-title">Create App Configuration</h3>
                <div className="card-body">

                
                  
                  <div className="card rounded p-3 shadow-lg">
                    
                  <form onSubmit={handleSubmit} className='row'>

                  <h6 className=' mt-3 font-bold'>App configuration</h6>


                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="app_name">App Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="app_name"
                        name="app_name"
                        value={appName.app_name}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="login_url">Login URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="login_url"
                        name="login_url"
                        value={appName.login_url}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="signup_url">Signup URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="signup_url"
                        name="signup_url"
                        value={appName.signup_url}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="backend_base_url">Backend Base URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="backend_base_url"
                        name="backend_base_url"
                        value={appName.backend_base_url}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <h6 className=' mt-3 font-bold'>Email Configuration</h6>


                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={appName.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                  
                

                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="email_sender">Email Sender</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email_sender"
                        name="email_sender"
                        value={appName.email_sender}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="email_password">Email Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="email_password"
                        name="email_password"
                        value={appName.email_password}
                        onChange={handleChange}
                        required
                      />
                    </div>

                   

                    <h6 className=' mt-3 font-bold'>SMS Configuration</h6>


                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="backend_base_url">SMS username</label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        value={appName.username}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="backend_base_url">API Key</label>
                      <input
                        type="text"
                        className="form-control"
                        id="api_key"
                        name="api_key"
                        value={appName.api_key}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                      <label htmlFor="backend_base_url">SMS sender</label>
                      <input
                        type="text"
                        className="form-control"
                        id="sender"
                        name="sender"
                        value={appName.sender}
                        onChange={handleChange}
                        required
                      />
                    </div>



                    <button type="submit" className="btn btn-primary mt-4 " style={{width: "fit-content", height: "fit-content"}}>
                      Submit
                    </button>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateAppConfig;
