import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import TableLoader from "../../common/TableLoader";
import Spinner from "../../common/Spinner";
import { API_BASE_URL } from "../../apiConfig";



const EablStaff = () => {
  const [eablStaffMembers, setEablStaffMembers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);



  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }



  const getEablStaffMembers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/accounts/eabl_staff_members/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      //   if(response.status === 401) {
      //     handleTokenExpiry()
      //     return
      //   }

      const data = await response.json();
      setEablStaffMembers(Array.isArray(data) ? data : []);
      console.log("Eabl Staff Members", data);
    } catch (error) {
      console.log("an error occurred while fetching eabl staff members");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredEablStaffMembers = eablStaffMembers.filter((member) => {
    return (
      member.first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      member.last_name
        ?.toLowerCase()
        .includes(searchText.toLocaleLowerCase()) ||
      member.eabl_coy
        ?.toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase()) ||
      member.mem_number
        ?.toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase())
    );
  });

  const columns = [
    {
      name: "First name ",
      selector: (row) => `${row.first_name} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Last name",
      selector: (row) => `${row.last_name} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Gender",
      selector: (row) => `${row.gender} `,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "COY number",
      selector: (row) => `${row.eabl_coy} `,
      sortable: true,
      className: "bold-header",
    },

   

    {
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn  btn-sm text-xs"
            type="button"
            id={`dropdownMenuButton-${row.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="fa fa-ellipsis-v"></span>
          </button>
          <div
            className="dropdown-menu text-xs"
            aria-labelledby={`dropdownMenuButton-${row.id}`}
          >
            <>
             

              <a className="text-white" href={`/main/eabl_staff/${row.staff_id}`}>
                <button className="dropdown-item ">View</button>
              </a>

            </>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "60px", // Set a minimum width for the dropdown button
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      getEablStaffMembers();
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="content-wrapper" style={{ minHeight: "90vh" }}>
          <section className="content-header ">
            <div className="container-fluid ">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h5>Eabl Staff Members</h5>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/main/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Eabl Staff Members
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-body ">
                      <div className="container   mt-5">
                        <div className="row ">
                          <div className="col-sm-6">
                            <h3 className="card-title text-bold">
                                Eabl Staff Members
                            </h3>
                          </div>
                          <div className="col-sm-6 text-right ">
                            <button
                              type="button"
                              className="button-primary-outline"
                              style={{ float: "right", marginRight: "10px" }}
                              onClick={() => {
                                const csv = eablStaffMembers.map((member) => ({
                                    cost_center: member.cost_center,
                                    eabl_coy: member.eabl_coy,
                                    mem_number: member.mem_number,
                                    medical_card_no: member.medical_card_no,
                                    first_name: member.first_name,
                                    middle_name: member.middle_name,
                                    last_name: member.last_name,
                                    gender: member.gender,
                                    date_of_birth: member.date_of_birth
                                
                                }));
                                const csvData = convertToCSV(csv);
                                const blob = new Blob([csvData], {
                                  type: "text/csv",
                                });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.setAttribute("href", url);
                                a.setAttribute(
                                  "download",
                                  "EABL Staff Members.csv"
                                );
                                a.click();
                              }}
                            >
                              Export to CSV
                            </button>

                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Search..."
                              value={searchText}
                              onChange={handleSearch}
                              style={{
                                width: "200px",
                                float: "right",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                  

                      <div className="container border rounded mt-5">
                        <div>
                          {loading}
                          <DataTable
                            columns={columns}
                            data={filteredEablStaffMembers}
                            fixedHeader
                            responsive={true}
                            selectableRows
                            progressPending={loading}
                            progressComponent={<TableLoader />}
                            onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                            striped // Enable row striping
                            highlightOnHover // Highlight row
                            pagination // Paginate
                          />
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default EablStaff;
