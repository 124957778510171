import React from 'react'

const Triage = () => {
  return (
    <div>
        <div className="container mt-4 ">
    <div className="card p-3">
        <div className="card-title">
            <div className="text-center">
                <img width={250}  src="/NWHlogo.PNG " alt="Medihub logo" />
            </div>
            <div className="card-body ">
                
                <div className="center">
              

                    <p className="text-center">Welcome to Eabl October Wellness month, please proceed to the <strong className="text-primary text-uppercase">wellness check center</strong>  for physical registration.</p>
                    
              </div>
    
            </div>
        </div>
        
    </div>
   
</div>
    </div>
  )
}

export default Triage