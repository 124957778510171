import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../apiConfig";

const PasswordReset = () => {
  const [formData, setFormData] = useState({
    email: "",
    phone_number: "+254"
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_BASE_URL}/accounts/password_reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Email for password reset sent succesifully successfully");
        navigate('/password_reset_request')
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      console.log("An error occurred while submitting data");
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if(name === "phone_number") {
      let phoneNumber = value.startsWith("+254") ? value : `+254${value.replace(/^(\+?254)?/, '')}`;
      if (phoneNumber.startsWith("+2540")) {
        phoneNumber = phoneNumber.replace("+2540", "+2547");
      }

      if(phoneNumber.length <= 13) {
        setFormData ({
          ...formData,
          [name]: phoneNumber
        }) 
      }
     
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    }
   
  return (
    <div style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"></div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm rounded border-0">
                <div className="card-body">
                  <div className="container  ">
                    <div className="row justify-content-center">
                      <div className="d-flex align-items-center justify-content-between">
                        <img src="/EABL.png" alt="EABL logo" width={100} />

                        <img
                          src="/NWHlogo.PNG"
                          alt="Medihub logo"
                          width={100}
                        />
                      </div>
                      <div className=" col-12">
                        <h6 className=" text-bold">Password reset</h6>
                        <div className="card rounded p-3 shadow-lg">
                          <form onSubmit={handleSubmit}>
                            <div className=" d-flex flex-column align-items-center justify-content-center">
                              <div className="col-lg-8 col-md-10 col-sm-12 mb-2 ">
                                <label htmlFor="name">
                                  Enter your email address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  required
                                />
                              </div>


                              <div className="col-lg-8 col-md-10 col-sm-12 mb-2 ">
                                <label htmlFor="name">
                                 phone number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="phone_number"
                                  name="phone_number"
                                  value={formData.phone_number}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className=" my-2">
                                {isSubmitting ? (
                                  <button
                                    className="btn btn-primary w-100"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary "
                                    disabled={isSubmitting}
                                  >
                                    submit
                                  </button>
                                )}
                              </div>
                         
                            </div>
                            <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
                            {errorMessage && (
                          <div className="alert alert-danger">
                            {errorMessage}
                          </div>
                        )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PasswordReset;
