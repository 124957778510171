import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../apiConfig";
import { useState } from "react";

const handleLogout = async (navigate) => {

 

  try {
    const response = await fetch(`${API_BASE_URL}/accounts/logout/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    if (response.ok) {
      console.log("Successful logout");
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
      localStorage.removeItem("role");

    
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 3000);
    } else {
      console.log("An error occurred while logging out");
    }
  } catch (error) {
    console.log("An error occurred while logging out", error);
  }
};


const LogoutButton = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  

  const handleLogoutClick = () => {
    handleLogout(navigate);
    setIsLoading(true)

    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 3000);

    return () => clearTimeout(timer)
    
  };

  return (
    <>

    {isLoading? (
      <button
      className="button-primary"
      type="button"
      disabled
    >
      <span
        className="spinner-border button-primary spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Loading...
    </button>
  ) : (
    <button className=" button-primary " onClick={handleLogoutClick}>
      logout
    </button>
    )}
    
    </>
  );
};

export default LogoutButton;
