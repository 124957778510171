import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
  const [formData, setFormData] = useState({
    mobile_number: '',
    sex: '',
    nationality: '',
    location: '',
    next_of_kin: '',
    relationship: '',
    next_of_kin_phone_number: '',
    spouse: false,
    principal: false,
    company: '',
    department: '',
    worklocation: '',
    subfunction: '',
    staff_number: '',
    employer_contact_person: '',
    employer_contact: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [worklocations, setWorklocations] = useState([]);
  const [subfunctions, setSubfunctions] = useState([]);
  const [showFields, setShowFields] = useState({
    company: false,
    department: false,
    worklocation: false,
    subfunction: false,
    staff_number: false
  });

  const navigate = useNavigate();

  useEffect(() => {
    setShowFields({
      company: formData.principal,
      department: formData.principal,
      worklocation: formData.principal,
      subfunction: formData.principal,
      staff_number: formData.spouse || formData.principal
    });
  }, [formData.spouse, formData.principal]);

  const fetchWorklocations = async (departmentId) => {
    try {
      const response = await fetch(
        `http://127.0.0.1:8000/accounts/get_worklocations/?department_id=${departmentId}`
      );
      const data = await response.json();
      setWorklocations(data.data);
    } catch (error) {
      console.error('Error fetching work locations:', error);
    }
  };

  const fetchSubfunctions = async (worklocationId) => {
    try {
      const response = await fetch(
        `http://127.0.0.1:8000/accounts/get_subfunctions/?worklocation_id=${worklocationId}`
      );
      const data = await response.json();
      setSubfunctions(data.data);
    } catch (error) {
      console.error('Error fetching subfunctions:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      // Add the actual form submission logic here
    } catch (error) {
      setErrorMessage('Error submitting the form.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  return (
    <div className='h-[90vh]'>
      <style>
        {`
          .form-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
          }

          #nextButton, #submitButton {
            margin-left: 10px;
          }

          .fa-star {
            font-size: 20px;
          }

          .yellow-star {
            background-color: #eaa606;
            border-radius: 4px;
            color: #b2481b;
          }

          #submitForm {
            width: fit-content;
            margin: 10px;
          }

          .toggle-btn {
            width: fit-content;
            margin-left: 10px;
          }

          .med-logo {
            margin-bottom: 2rem;
          }

          .form-color {
            background-color: #b2481b;
          }

          .nav-tabs .nav-item.active a {
            background-color: #b2481b; 
            color: #fff; 
          }

          .submitbuttons {
            display: flex;
            gap: 10px;
          }

          .image-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 20px;
          }

        .image-container img {
            width: 80px;
            height: auto;
          }
        `}
      </style>
      <div className='flex items-center justify-center h-full'>
        <div className='w-full max-w-lg'>
          <p className='font-bold text-2xl text-center my-2'>
            Wellness Registration
          </p>
          <form onSubmit={handleSubmit}>
            {errorMessage && (
              <p className='text-red-500 text-sm'>{errorMessage}</p>
            )}
            <div className="image-container">
              <img src="/EABL.png" alt="EABL logo" />
              <img src="/NWHlogo.PNG" alt="mediHub Logo" />
            </div>

            <section className="personal-info-section">
              <div>
                <p>Username: </p>
                <p>Full Name: </p>
                <p>Email Address: </p>
              </div>
            </section>
            
            <div className='form-grid'>
              <div className='form-group'>
                <label htmlFor='mobile_number'>Mobile Number</label>
                <input
                  type='text'
                  name='mobile_number'
                  id='mobile_number'
                  value={formData.mobile_number}
                  onChange={handleChange}
                  className='form-control'
                />
              </div>

              <div className='form-group'>
                <label htmlFor='nationality'>Nationality</label>
                <input
                  type='text'
                  placeholder='e.g Kenyan'
                  name='nationality'
                  id='nationality'
                  className='form-control'
                  value={formData.nationality}
                  onChange={handleChange}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='sex'>Sex</label>
                <input
                  type='text'
                  name='sex'
                  id='sex'
                  className='form-control'
                  value={formData.sex}
                  onChange={handleChange}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='location'>Location</label>
                <input
                  type='text'
                  name='location'
                  id='location'
                  className='form-control'
                  value={formData.location}
                  onChange={handleChange}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='next_of_kin'>Next of Kin</label>
                <input
                  type='text'
                  placeholder='e.g Susan'
                  name='next_of_kin'
                  id='next_of_kin'
                  className='form-control'
                  value={formData.next_of_kin}
                  onChange={handleChange}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='relationship'>Relationship</label>
                <input
                  type='text'
                  placeholder='e.g Wife'
                  name='relationship'
                  id='relationship'
                  className='form-control'
                  value={formData.relationship}
                  onChange={handleChange}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='next_of_kin_phone_number'>
                  Next of Kin Phone Number
                </label>
                <input
                  type='text'
                  placeholder='072268...'
                  name='next_of_kin_phone_number'
                  id='next_of_kin_phone_number'
                  className='form-control'
                  value={formData.next_of_kin_phone_number}
                  onChange={handleChange}
                />
              </div>

              <div className='form-group'>
                <label>
                  <input
                    type='checkbox'
                    name='spouse'
                    checked={formData.spouse}
                    onChange={handleChange}
                  />{' '}
                  Spouse
                </label>
                <label>
                  <input
                    type='checkbox'
                    name='principal'
                    checked={formData.principal}
                    onChange={handleChange}
                  />{' '}
                  Principal
                </label>
              </div>

              {showFields.department && (
                <div className='form-group'>
                  <label htmlFor='department'>Department</label>
                  <input
                    type='text'
                    name='department'
                    id='department'
                    className='form-control'
                    value={formData.department}
                    onChange={handleChange}
                  />
                </div>
              )}

              {showFields.worklocation && (
                <div className='form-group'>
                  <label htmlFor='worklocation'>Work Location</label>
                  <input
                    type='text'
                    name='worklocation'
                    id='worklocation'
                    className='form-control'
                    value={formData.worklocation}
                    onChange={handleChange}
                  />
                </div>
              )}

              {showFields.subfunction && (
                <div className='form-group'>
                  <label htmlFor='subfunction'>Subfunction</label>
                  <input
                    type='text'
                    name='subfunction'
                    id='subfunction'
                    className='form-control'
                    value={formData.subfunction}
                    onChange={handleChange}
                  />
                </div>
              )}

              {showFields.company && (
                <div className='form-group'>
                  <label htmlFor='company'>Company</label>
                  <input
                    type='text'
                    name='company'
                    id='company'
                    className='form-control'
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
              )}

              {showFields.staff_number && (
                <div className='form-group'>
                  <label htmlFor='staff_number'>Staff Number</label>
                  <input
                    type='text'
                    placeholder='e.g EABL30...018'
                    name='staff_number'
                    id='staff_number'
                    className='form-control'
                    value={formData.staff_number}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>

            <div className='submitbuttons'>
              <button
                type='submit'
                className='btn text-white text-bold form-color btn-auto'
                id='submitForm'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;


